const BE_HOST = 'https://auth.triton.demo.kayros-lab.com'
const REALM = 'triton'
const CLIENT_ID = 'account'
const REDIRECT_URI = encodeURIComponent('https://triton.demo.kayros-lab.com/sign-in-successfull')
const LOGOUT_REDIRECT_URI = encodeURIComponent('https://triton.demo.kayros-lab.com/logout-successfull')
export const environment = {
  graphql_endpoint: 'https://api.triton.demo.kayros-lab.com/graphql',
  ws_endpoint: 'wss://api.triton.demo.kayros-lab.com/subscription',
  keycloak: {
    url: `${BE_HOST}`,
    login_page: `${BE_HOST}/realms/${REALM}/protocol/openid-connect/auth?redirect_uri=${REDIRECT_URI}&response_type=token&client_id=${CLIENT_ID}&code_challenge_method=plain&scope=openid`,
    logout_url: `${BE_HOST}/realms/${REALM}/protocol/openid-connect/logout?post_logout_redirect_uri=${LOGOUT_REDIRECT_URI}`,

  },

};
