import {Injectable, OnDestroy} from '@angular/core';
import {combineLatest, filter, map, Observable, Subject, takeUntil} from "rxjs";

import {Apollo, gql, MutationResult} from "apollo-angular";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";
import {ReservationDTO} from "./dto";
import {BERTH_FIELD} from "./berth.service";
import {BOAT_FIELD} from "./boat.service";

export const RESERVATION_FIELD = gql`
  fragment RESERVATION_FIELD on ReservationDTO {
    id
    updatedAt
    toDate
    fromDate
    createdAt
    berthId
  }
`;


const add = gql`
  ${RESERVATION_FIELD}

  mutation AddReservation($dto: ReservationInput!) {
    reservation_add_one(body: $dto) {
      ...RESERVATION_FIELD
    }
  }`
const update = gql`
  ${RESERVATION_FIELD}

  mutation UpdateReservation($id: Int!, $dto: ReservationInput!) {
    reservation_update_one(pk_columns: {id: $id}, body: $dto){
      ...RESERVATION_FIELD

    }

  }`


const getAll = gql`
  ${RESERVATION_FIELD}
  ${BERTH_FIELD}
  ${BOAT_FIELD}

  query GetAllReservations($pageNumber: Int, $pageDim: Int, $where:String, $sort: String = "id,desc" $includeBerth: Boolean = false, $includeBoat: Boolean = false ) {
    reservation(pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: $sort) {
      ...RESERVATION_FIELD
      berth @include(if: $includeBerth){
        ...BERTH_FIELD
      }
      boat @include(if: $includeBoat){
        ...BOAT_FIELD
      }
    }
  }
`;

const getById = gql`
  ${RESERVATION_FIELD}
  ${BERTH_FIELD}

  query GetReservationById($id: ID!, $includeBerth: Boolean = false) {
    reservation_by_pk(id: $id) {
      ...RESERVATION_FIELD
      berth @include(if: $includeBerth) {
        ...BERTH_FIELD
      }
    }
  }`;


const count = gql`query CountReservation( $where: String ) {
  reservation_aggregate(aggregation: {count: {field: "id", distinct: false}}, where: $where) {
    count
  }
}`;


@Injectable({
  providedIn: 'root'
})
export class ReservationService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo) {

  }

  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation, sort?: string, includes?: {
    berth?: boolean,
    boat?: boolean
  }): Observable<{
    data: ReservationDTO[],
    totalRows: number
  }> {
    const inc = {
      ...{berth: false, boat: false},
      ...includes
    }
    const sortVal = sort ?? 'id,desc';

    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
          sort: sortVal,
          includeBerth: inc.berth,
          includeBoat: inc.boat
        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['reservation_aggregate']
        // @ts-ignore
        const data = x[0].data?.['reservation']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  getById(id: number, includes: { berth: boolean }): Observable<ReservationDTO> {

    const inc = {...{berth: false}, ...includes}
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
        includeBerth: inc.berth
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      filter(x => !x.loading),
      map(x => {
          // @ts-ignore
          const data = x.data?.['reservation_by_pk'];
          const d = (({__typename, ...o}) => o)(data)
          return d
        }
      )
    );
  }

  count(filters?: BoolOperation): Observable<number> {
    return this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(
        filter(c => !c.loading),
        map(x => {
            // @ts-ignore
            const aggRes = x.data['reservation_aggregate']
            return aggRes.count
          }
        )
      )
  }


  add(dto: ReservationDTO): Observable<ReservationDTO> {
    return this.apollo.mutate({
      mutation: add,
      variables: {dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.reservation_add_one))
  }

  update(id: number, dto: ReservationDTO): Observable<ReservationDTO> {
    return this.apollo.mutate({
      mutation: update,
      variables: {id: id, dto: dto},
      fetchPolicy: 'no-cache',

    }).pipe(map((x: MutationResult) => x.data.reservation_update_one))
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
