import {InsuranceDataService} from "./insurance-data.service";

export enum BoatEngineDTO_PropulsionType {
  SAILING = 'SAILING',
  OARS = 'OARS',
  ENGINE = 'ENGINE'
}

export interface BoatEngineDTO {
  id: number;
  model: string;
  brand: string;
  serialNumber: string;
  kw: number;
  cv: number;
  boatId: number;
  propulsionType: BoatEngineDTO_PropulsionType;
  createdAt: Date;
  updatedAt: Date;
}

export interface BoatDTO {
  id: number
  beam: number
  createdAt: Date
  description: string
  draft: number
  length: number
  boatModel: string
  plateNumber: string
  flag: CountryDTO
  flagId: number
  name: string
  shipOwnerId: number
  shipOwner?: ShipOwnerDTO
  type?: BoatTypeDTO
  typeId: number
  updatedAt: Date
  maxPassengers: number
  boatImages?: AttachmentDTO[]
  insuranceData?: InsuranceDataDTO[]
  engine?: BoatEngineDTO;
}

export interface CountryDTO {
  id: number
  name: string
}

export interface BoatTypeDTO {
  id: number
  name: string
}

enum OwnerType {
  PERSON = "PERSON",
  COMPANY = "COMPANY"
}

export interface ShipOwnerDTO {
  id: number;
  firstname: string;
  lastname: string;
  vatCode: string;
  taxCode: string;
  mobilePhone: string;
  phone: string;
  email: string;
  pec: string;
  type: OwnerType;
}

export interface BerthDTO {
  id: number
  code: string
  length: number
  portId: number
  port?: PortDTO
  createdAt: Date
}

export interface InsuranceDataDTO {
  id: number
  companyName: string
  insurancePremium: number
  insurancePolicyName: string
  expirationDate: Date;
  boatId: number
  boat?: BoatDTO
}

export enum CategoryType {
  TRANSITO = "TRANSITO",
  MENSILE = "MENSILE",
  PERIODICO = "PERIODICO"
}

export interface PriceCategoryDTO {
  id: number

  name: string;
  fromDate: Date;
  toDate: Date;
  type: CategoryType;

  berthPrices?: BerthPriceDTO[];
}

export interface BerthPriceDTO {
  id: number
  berthId: number
  categoryId: number
  price: number
  category?: PriceCategoryDTO
  berth?: BerthDTO
}

export interface PortDTO {
  id: number;
  name: string;
  bgImageUrl: string;
  mapUrl: string;

  maxLenght: number;
  draft: number;
  vhfChannel: string;

  berths: BerthDTO[]
}

export interface ReservationDTO {
  id: number
  updatedAt: Date
  createdAt: Date

  fromDate: Date
  toDate: Date
  berthId: number
  berth?: BerthDTO
  boatId: number
  boat?: BoatDTO
}

export enum AttachmentType {
  BOAT_IMAGE = "BOAT_IMAGE",
  BOAT_DOC = "BOAT_DOC",
  GENERIC = "GENERIC",
  PORT_IMAGE = "PORT_IMAGE"
}

export interface AttachmentDirectoryDTO {
  id: number
  name: string
  createdAt: Date
  parentId: number
  parent?: AttachmentDirectoryDTO
  files?: AttachmentDTO[]
  boatId?: number
}

export interface AttachmentDTO {
  id: number
  name: string
  description: string
  expiration: Date;
  type: AttachmentType;
  mimeType: string;
  contentLength: number;
  url: string;
  directoryId?: number;
  parent?: AttachmentDirectoryDTO
  updatedAt: Date
  createdAt: Date
}

export interface ShipYardDataDTO {
  id: number;
  boatId: number;
  name: string;
  model: string;
  designer: string;
  buildYear: string;
  registrationDate: string;
  boat?: BoatDTO;
}
