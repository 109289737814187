import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, filter, map, Observable, Subject, takeUntil, tap } from "rxjs";
import { Apollo, gql, MutationResult } from "apollo-angular";
import { BoolOperation, ExpressionBuilder } from "./ExpressionBuilder";
import { BoatDTO } from "./dto";
import { SHIP_OWNER_FIELD } from "./ship-owner.service";
import { COUNTRY_FIELD } from "./country.service";
import { BOAT_TYPE_FIELD } from "./boat-type.service";
// import { ATTACHMENT_FIELD } from "./attachment.service";
import { BOAT_ENGINE_FIELD } from './boat-engine.service';

export const BOAT_FIELD = gql`
  fragment BOAT_FIELD on BoatDTO {
    beam
    createdAt
    description
    id
    draft
    length
    name
    shipOwnerId
    typeId
    updatedAt
    boatModel
    plateNumber
    flagId
    maxPassengers

  }
`;
export const BOAT_IMAGE_ATTACHMENT_FIELD = gql`

  fragment BOAT_IMAGE_ATTACHMENT_FIELD on BoatImageAttachmentDTO {
    id
    name
    description
    expiration
    type
    mimeType
    contentLength
    url
  }
`;
const add = gql`
  ${BOAT_FIELD}

  mutation AddBoat($dto: BoatInput!) {
    boat_add_one(body: $dto) {
      ...BOAT_FIELD
    }
  }`;

const update = gql`
  ${BOAT_FIELD}

  mutation UpdateBoat($id: ID!, $dto: BoatInput!) {
    boat_update_one(id: $id, body: $dto){
      ...BOAT_FIELD
    }
  }`;

const getAll = gql`
  ${BOAT_FIELD}
  ${SHIP_OWNER_FIELD}
  ${COUNTRY_FIELD}
  ${BOAT_ENGINE_FIELD}

  query GetAllBoats($pageNumber: Int, $pageDim: Int, $where:String,  $includeEngine: Boolean = false ) {
    boat(pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: "id,desc") {
      ...BOAT_FIELD
      shipOwner {
        ...SHIP_OWNER_FIELD
      }
      flag{
        ...COUNTRY_FIELD
      }
      engine @include(if: $includeEngine){    #TODO aggiungere un $includeEngine e condizionare questo valore
        ...BOAT_ENGINE_FIELD
      }
    }
  }
`;

const getById = gql`
  ${BOAT_FIELD}
  ${SHIP_OWNER_FIELD}
  ${COUNTRY_FIELD}
  ${BOAT_TYPE_FIELD}
  ${BOAT_IMAGE_ATTACHMENT_FIELD}
  ${BOAT_ENGINE_FIELD}

  query GetBoatById($id: ID!, $includeImages: Boolean = false, $includeEngine: Boolean = false) {
    boat_by_pk(id: $id) {
      ...BOAT_FIELD
      type {
        ...BOAT_TYPE_FIELD
      }
      shipOwner {
        ...SHIP_OWNER_FIELD
      }
      flag{
        ...COUNTRY_FIELD
      }
      engine @include(if: $includeEngine) {    #TODO aggiungere un $includeEngine e condizionare questo valore
        ...BOAT_ENGINE_FIELD
      }
      boatImages @include(if: $includeImages) {
        ...BOAT_IMAGE_ATTACHMENT_FIELD
      }
    }
  }`;

const getByOwnerIdQuery = gql`
  ${BOAT_FIELD}
  query GetBoatsByOwnerId($where: String!) {
    boat(where: $where) {
      ...BOAT_FIELD
    }
  }
`;

const count = gql`query CountBoat( $where: String ) {
  boat_aggregate(aggregation: {count: {field: "id", distinct: false}}, where: $where) {
    count
  }
}`;

@Injectable({
  providedIn: 'root'
})
export class BoatService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo) { }

  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation, includeEngine: boolean = false): Observable<{
    data: BoatDTO[],
    totalRows: number
  }> {

    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
          includeEngine: includeEngine
        },
        fetchPolicy: 'no-cache',
      }).valueChanges.pipe(filter(c => !c.loading));

    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',
      }).valueChanges.pipe(filter(c => !c.loading));

    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      tap(x => console.log(x)),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['boat_aggregate']
        // @ts-ignore
        const data = x[0].data?.['boat']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  getById(id: number, includes: { includeImages: boolean, includeEngine: boolean } = { includeImages: false, includeEngine: false}): Observable<BoatDTO> {
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
        includeImages: includes.includeImages,
        includeEngine: includes.includeEngine
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      filter(x => !x.loading),
      map(x => {
          // @ts-ignore
          const data = x.data?.['boat_by_pk'];
          const d = (({__typename, ...o}) => o)(data);
          console.log("Data from getById:", d);
          return d
        }
      )
    );
  }

  getByOwnerId(ownerId: number): Observable<BoatDTO[]> {
    const where = `shipOwnerId==${ownerId}`;
    return this.apollo.watchQuery({
      query: getByOwnerIdQuery,
      variables: {
        where: where,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      map(x => {
        // @ts-ignore
        const data = x.data?.['boat'];
        return data.map((y: any) => (({ __typename, ...o }) => o)(y));
      })
    );
  }

  add(dto: BoatDTO): Observable<BoatDTO> {
    return this.apollo.mutate({
      mutation: add,
      variables: { dto: dto },
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.insert_company_one));
  }

  update(id: number, dto: BoatDTO): Observable<BoatDTO> {
    return this.apollo.mutate({
      mutation: update,
      variables: { id: id, dto: dto },
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.update_company_by_pk));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
